import { cloudApi } from "../api";
import $tu from "../utils/$tu";

export default {
  namespaced: true,
  state: {
    options: {}
  },
  getters: {
    Diagnosis: state => state.options["Diagnosis"],
    OrderBaseType: state => state.options["OrderBaseType"],
    OrderCategory: state => state.options["OrderCategory"],
    OrderCategoryLocalized: state =>
      (state.options["OrderCategory"] &&
        state.options["OrderCategory"].filter(value => !value.deprecated).map(value => ({
          text: $tu(value),
          value: value.id
        }))) ||
      null,
    OrderMaterial: state => state.options["OrderMaterial"],
    OrderMaterialLocalized: state =>
      (state.options["OrderMaterial"] &&
        state.options["OrderMaterial"].filter(value => !value.deprecated).map(value => ({
          text: $tu(value),
          value: value.id
        }))) ||
      null,
    OrderCategoryUnfilteredLocalized: state =>
      (state.options["OrderCategory"] &&
        state.options["OrderCategory"].map(value => ({
          text: $tu(value),
          value: value.id
        }))) ||
      null,
    OrderMaterialUnfilteredLocalized: state =>
      (state.options["OrderMaterial"] &&
        state.options["OrderMaterial"].map(value => ({
          text: $tu(value),
          value: value.id
        }))) ||
      null      
  },
  mutations: {
    setOptions: (state, { key, value }) => {
      const newOptions = { ...state.options };
      newOptions[key] = value;
      state.options = newOptions;
    }
  },
  actions: {
    async fetchOptions({ commit }, key) {
      const value = (
        await cloudApi.get("get-option", { params: { OptionType: key } })
      ).data.Items;
      // Filter out deprecated items
      // const value = items.filter(item => !item.deprecated);
      commit("setOptions", { key, value });
    }
  }
};
